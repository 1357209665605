"use strict";

const serialize = require("form-serialize");
const RSVP = require("es6-promise").Promise;

require("../../components/semantic/dist/components/popup");
require("../../components/semantic/dist/components/transition");
require("../../components/semantic/dist/components/modal");
require("../../components/semantic/dist/components/dimmer");
require("../../components/semantic/dist/components/tab");
require("../../components/semantic/dist/components/calendar");

const analytics = require("./../utils/analytics.es6");
const fieldConditions = require("./../utils/field-conditions.es6");
const files = require("./../utils/files.es6");
const formValidation = require("./../utils/form-validation.es6");
const hubpubsub = require("./../utils/hubpubsub.es6");
const modalComponent = require("./../utils/modal.es6");

let groupId, reqType, embedded, mode, linkedTaskflows, todos, dynamicFields, wysiwyg, timerField;

export const startup = (gid, req, emb, selector, mde) => {
  groupId = gid;
  reqType = req;
  embedded = emb;
  mode = mde;

  if (reqType == "modal" || reqType == "hub") {
    startupInModal(selector);
  } else {
    startupInPage("#kotive-container");
  }
};

const startupInPage = (selector) => {
  refreshPageTitle(selector);
  fieldConditions.startup(selector);
  enableGateway(selector);
  enableTabs(selector);
  enableFormSections(selector);
  enableDatepicker(selector);
  enableFileUploads(selector);
  enableWysiwyg(selector);
  enableDynamicFields(selector);
  enableVideo(selector);
  enableAudio(selector);
  enablePowerpoint(selector);
  enableLinkedTaskflows(selector);
  //enableAnalytics()
  enablePageControls(selector);
  enableTimerFields(selector);
  enableTodos(selector);
  observeEnterPress(selector);
  formValidation.observeEmailFields();
  formValidation.observeTelFields();
  setupEventPageSave(selector);
  triggerPostMessage("loaded");
};

/* PAGE specific functions */
const recaptchaSubmission = (reCaptchaSiteKey, uname, submissionUrl) => {
  return new RSVP.Promise((resolve, reject) => {
    if (reCaptchaSiteKey && uname == "anonymous@kotive.com" && submissionUrl.indexOf("taskflow/execute") === -1) {
      grecaptcha.ready(() => {
        grecaptcha.execute(reCaptchaSiteKey, { action: "submit" }).then((token) => {
          $.ajax({
            url: `/apiv3/taskflows/recaptcha`,
            method: "POST",
            data: { token: token },
          })
            .done((data, textStatus, jqxhr) => {
              if (data.score >= 0.5) {
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .fail((jqxhr, textStatus, errorThrown) => {
              console.error(`Failed to check for ReCaptcha... ${jqxhr.status}... (${textStatus}`);
              resolve(false);
            });
        });
      });
    } else {
      resolve(true);
    }
  });
};
const refreshPageTitle = (selector) => {
  document.title = $(`${selector} #task-title`).html() + " | Kotive";
  $(`${selector} .kotive-task-header`).html($(`${selector} #task-title`).html());
};
const clearPageTitle = (selector) => {
  document.title = "Thank you!";
  $(`${selector} .kotive-task-header`).remove();
};
const enablePageControls = (selector) => {
  $(`${selector} #kotive-task-save-btn`).removeClass();
  $(`${selector} #kotive-task-saving`).addClass(`hidden`);
};
const disablePageControls = (selector) => {
  $(`${selector} #kotive-task-save-btn`).addClass("hidden");
  $(`${selector} #kotive-task-saving`).removeClass();
};
const hidePageControls = (selector) => {
  $(`${selector} #kotive-task-save-btn`).addClass("hidden");
  $(`${selector} #kotive-task-saving`).addClass("hidden");
};
const triggerPostMessage = (event) => {
  window.parent.postMessage(`kotive.${event}`, "*");
};
const setupEventPageSave = (selector) => {
  $("body").on("click", `${selector} #kotive-task-save-btn`, (e) => {
    e.preventDefault();

    hubpubsub.publish("form.save.initiated", { selector });

    let username, token;
    if (typeof localStorage !== "undefined" && localStorage !== null) {
      username = localStorage.getItem("user-email") || "anonymous@kotive.com";
      token = localStorage.getItem("auth-token") || "";
    } else {
      username = "anonymous@kotive.com";
      token = "";
    }

    disablePageControls(selector);

    const forms = $(`${selector} .kotive-task-body form`);

    recaptchaSubmission(reCaptchaSiteKey, username, forms[0].action).then((legitSubmission) => {
      if (legitSubmission) {
        let ajaxResponses = [];

        let deferreds = forms.map((index, form) => {
          if (username !== "anonymous@kotive.com" && form.action.indexOf("asAnon=true") !== -1) {
            // overwrite logged-in user as anon
            username = "anonymous@kotive.com";
            token = "";
          }
          $("<input>").attr({ type: "hidden", name: "username", value: username }).appendTo(form);
          $("<input>").attr({ type: "hidden", name: "token", value: token }).appendTo(form);

          const csfrToken =
            document.querySelector('meta[name="csrf-token"]') !== null ? document.querySelector('meta[name="csrf-token"]').content : "";
          return $.ajax({
            url: form.action,
            method: "POST",
            headers: { "x-csrf-token": csfrToken },
            data: serialize(form, { empty: true, hash: false }),
            success: (data, textStatus, jqXHR) => {
              ajaxResponses.push(data);
            },
          });
        });

        $.when.apply(null, deferreds.get()).then(
          () => {
            $(`${selector} .kotive-task-content`).html(ajaxResponses.pop());

            setTimeout(() => {
              triggerPostMessage("saved");
              if ($(`${selector} .kotive-task-content`).find("form").length === 0) {
                clearPageTitle(selector);
                hidePageControls(selector);
                triggerPostMessage("completed");

                $(`${selector} #kotive-task-report-link`).on("click", (e) => {
                  e.preventDefault();
                  window.top.location.href = $(`${selector} #kotive-task-report-link`).attr("href");
                  return false;
                });

                $(`${selector} #kotive-task-history-link`).on("click", (e) => {
                  e.preventDefault();
                  window.top.location.href = $(`${selector} #kotive-task-history-link`).attr("href");
                  return false;
                });
              } else {
                refreshPageTitle(selector);
                enablePageControls(selector);
                fieldConditions.startup(selector);
                enableGateway(selector);
                enableTabs(selector);
                enableFormSections(selector);
                enableDatepicker(selector);
                enableFileUploads(selector);
                enableWysiwyg(selector);
                enableDynamicFields(selector);
                enableVideo(selector);
                enableAudio(selector);
                enablePowerpoint(selector);
                enableLinkedTaskflows(selector);
                enableTimerFields(selector);
                enableTodos(selector);
                triggerPostMessage("reloaded");
              }

              $("body").scrollTop(0);
              observeEnterPress(selector);
              history.pushState(null, null, $(`${selector} #taskflow-continueLink`).html());
            }, 100);
          },
          (err) => {
            switch (err.status) {
              case 402:
                if (reqType === "full" || reqType === "hub" || reqType === "modal") amplitude.getInstance().logEvent("Credits depleted");
                alert(
                  "Credits depleted. Sorry, you cannot submit the form at this time because the account's credits have been depleted. The account manager has been notified. Please try again later."
                );
                break;
              default:
                console.log(`Error: [${err.status}] (${err.statusText})`);
                alert("Sorry, something went wrong when trying to submit your form, please try again in a few minutes.");
            }
          }
        );
      } else {
        alert("Did not pass ReCaptcha test. Please try again.");
      }
    });
  });
};

/* MODAL specific functions */
export const startupInModal = (selector) => {
  fieldConditions.startup(selector);
  enableSelects(selector);
  enableTabs(selector);
  enableFormSections(selector);
  enableDatepicker(selector);
  enableFileUploads(selector);
  enableWysiwyg(selector);
  enableDynamicFields(selector);
  enableVideo(selector);
  enableAudio(selector);
  enablePowerpoint(selector);
  enableLinkedTaskflows(selector);
  enableTimerFields(selector);
  enableTodos(selector);
  //enableAnalytics()
  enableModalControls(selector);
  observeEnterPress(selector);
  triggerPostMessage("loaded");
};

export const updateReferrerModal = (opts) => {
  const fieldTestStr = "kotive-field-";
  if (opts.referrer != undefined && opts.referrer.indexOf(fieldTestStr) !== -1) {
    let processId = null;
    const formActionTestStr = "/hub/taskflow/execute/";
    let formUrl = $(`#${opts.uniqueId} form`).attr("action");
    if (formUrl != null && formUrl.indexOf(formActionTestStr) == 0) {
      formUrl = formUrl.substr(formActionTestStr.length).split("/");
      processId = formUrl[1];
    } else {
      processId = $(`#${opts.uniqueId} #processId`).val();
    }

    if (opts.referrer != null && opts.referrer.indexOf(fieldTestStr) !== -1 && processId != null) {
      if ($(`#${opts.referrer}`).length > 0) {
        const currentValue = $(`#${opts.referrer}`).val();
        let referrerFieldValues = currentValue != "" ? currentValue.split(",") : [];
        if (referrerFieldValues.indexOf(processId) === -1) {
          referrerFieldValues.push(processId);
          $(`#${opts.referrer}`).val(referrerFieldValues.join(","));
          const referralField = $(`#${opts.referrer}`).closest(".linkedtaskflows");
          linkedTaskflows.renderTaskflowField(groupId, referralField);
        }
      }
    }
  }
};
export const updateReferrerReport = (opts, hasSavedAtLeastOnce) => {
  if (/\/(.*)\/report\/(.*)\//.test(opts.referrer) && hasSavedAtLeastOnce) {
    hasSavedAtLeastOnce = false;
    hubpubsub.publish("reports.refresh", {});
  }
  return hasSavedAtLeastOnce;
};
export const refreshContent = (selector, reqType, data) => {
  $(`${selector} .content`).html(data);
  $(`${selector} .content .kotive-task-header`).hide();
  $(`${selector} .header`).html($(`${selector} .content #task-title`).html());
  if (reqType != "modal") document.title = $(`${selector} .content #task-title`).html();
};
export const hideModalControls = (selector) => {
  $(`${selector} .actions .button`).hide();
};
const enableModalControls = (selector) => {
  $(`${selector} .actions .button.positive`).removeClass("loading");
  $(`${selector} .actions .button`).show();
};
export const disableModalControls = (selector) => {
  $(`${selector} .actions .button.positive`).addClass("loading");
  $(`${selector} .actions .button.close`).hide();
};
export const enableModalCloseControlOnly = (selector) => {
  $(`${selector} .actions .button.positive`).hide();
  $(`${selector} .actions .button.close`).html("Close");
  $(`${selector} .actions .button.close`).show();
};

/* COMMON functions */
export const discoverDataOptions = (target, count) => {
  let obj = {};
  if (count > 2) return obj;
  obj = $(target).data();
  if ($(target).attr("href") != null) {
    obj.url = $(target).attr("href");
  } else if ($(target).data("url") != null) {
  } else {
    obj = discoverDataOptions($(target).closest("a, button"), count + 1);
  }
  return obj;
};

export const buildQueryString = (params, activeModals) => {
  const queryString = [];
  if (params != null) {
    Object.keys(params).forEach((key, i) => {
      let p = "";
      if (params[key].indexOf("_") === 0) {
        const fSelector = `[name^='kotive-field-'][name*='${params[key]}']`;
        if ($(fSelector).attr("type") == "radio") {
          p = $(`${fSelector}:checked`).val();
          if (p != null && p != "") queryString.push(`${key}=${encodeURIComponent(p)}`);
        } else if ($(fSelector).attr("type") == "checkbox") {
          $(`${fSelector}:checked`).each((i, v) => {
            const extracted = $(v)
              .attr("id")
              .match(/\[.*\]/);
            p = extracted[0].substring(1, extracted[0].length - 1);
            if (p != null && p != "") queryString.push(`${key}=${encodeURIComponent(p)}`);
          });
        } else {
          p = $(fSelector).val();
          if (p != null && p != "") queryString.push(`${key}=${encodeURIComponent(p)}`);
        }
      } else {
        if (activeModals.length > 0) {
          const extractedParams = extractQueryParameters(activeModals[activeModals.length - 1].url);
          if (extractedParams[params[key]] != null) queryString.push(`${key}=${encodeURIComponent(extractedParams[params[key]])}`);
        }
      }
    });
  }
  return queryString.length > 0 ? `${queryString.join("&")}` : "";
};

const extractQueryParameters = (url) => {
  let params = "";
  if (url.indexOf("?") !== -1) {
    params = [];
    const list = url.substr(url.indexOf("?") + 1).split("&");
    list.forEach((key, i) => {
      key = key.split("=");
      params[key[0]] = key[1];
    });
  }
  return params;
};

const enableSelects = (selector) => {
  if ($(`${selector} .ui.dropdown:not(.search)`).length > 0) {
    $(`${selector} .ui.dropdown:not(.search)`).dropdown({
      direction: "downward",
    });
  }
  if ($(`${selector} .ui.dropdown.search`).length > 0) {
    $(`${selector} .ui.dropdown.search`).dropdown({
      direction: "downward",
      fullTextSearch: true,
    });
  }
};
const enableGateway = (selector) => {
  let contact = localStorage.getItem("verified-contact");
  const contactTTL = localStorage.getItem("verified-contact-TTL");
  if (contactTTL != null && contact != null) {
    if (Date.now() < parseInt(contactTTL)) {
      if (contact !== undefined) {
        try {
          contact = JSON.parse(contact);
        } catch (e) {
          console.error(e);
          return false;
        }
      }
      if (contact.properties === undefined) contact.properties = {};

      const taskId = $(`${selector} .kotive-task-body form`).attr("data-form-id");
      if (contact.properties.workflows !== undefined && contact.properties.workflows[`_${taskId}`] !== undefined) {
        Object.keys(contact.properties.workflows[`_${taskId}`]).forEach((fid) => {
          const fSelector = `[name^='kotive-field-'][name*='${fid}']`;

          if ($(fSelector).attr("type") == "radio") {
            if ($(`${fSelector}:checked`).val() === undefined) {
              $(`${fSelector}`).each((i, radioField) => {
                if ($(radioField).val() == contact.properties.workflows[`_${taskId}`][fid]) $(radioField).prop("checked", true);
              });
            }
          } else if ($(fSelector).attr("type") == "checkbox") {
            if ($(`${fSelector}:checked`).length == 0) {
              const options = contact.properties.workflows[`_${taskId}`][fid].split("^");
              options.forEach((val) => {
                $(`[name^='kotive-field-'][name*='${fid}[${val}]']`).prop("checked", true);
              });
            }
          } else {
            if ($(fSelector).val() == "" && contact.properties.workflows[`_${taskId}`][fid] != "-")
              $(fSelector).val(contact.properties.workflows[`_${taskId}`][fid]);
          }
        });
      }
    }
  }
};
const enableTabs = (selector) => {
  if ($(`${selector} .kotive-task-tabheader .tabular.menu`).length > 0) {
    $(`${selector} .header`).hide();
    $(`${selector} .kotive-task-tabheader .tabular.menu .item`).tab();
    $(`${selector} .kotive-task-tabheader .tabular.menu .item:first, .kotive-task-body .tab:first`).addClass("active");
  }
};
const enableFormSections = (selector) => {
  const sections = $(`${selector} .section-tabs-js`).children();
  if (sections.length > 0) {
    sections.each((i, s) => {
      const sectionId = $(s).data("tab");
      $(`[data-fs='${sectionId}']`).appendTo($(`#${sectionId}`));
      if (i == 0) $(`[data-tab='${sectionId}']`).addClass("active");
    });
    $(`${selector} .section-tabs-js .item`).tab();
  }
};
const enableDatepicker = (selector) => {
  if ($(`${selector} .kotive-task-body #datepicker`).length > 0) {
    $(`${selector} .kotive-task-body #datepicker.ui.calendar`).calendar({
      type: "date",
    });
  }
  if ($(`${selector} .kotive-task-body #datetimepicker`).length > 0) {
    $(`${selector} .kotive-task-body #datetimepicker.ui.calendar`).calendar({
      type: "datetime",
    });
  }
};
const enableFileUploads = (selector) => {
  if (mode == "batch") $(`${selector} .kotive-task-body .fileuploads-field`).closest(".kotive-field-input").remove();
  if ($(`${selector} .kotive-task-body .fileuploads-field`).length > 0) {
    const taskId = $(`${selector} .kotive-task-body form`).attr("data-form-id");
    files.init(groupId, taskId, selector);
  }
};
const enableLinkedTaskflows = (selector) => {
  if (mode == "batch") $(`${selector} .kotive-task-body .linkedtaskflows`).closest(".kotive-field-input").remove();
  if ($(`${selector} .kotive-task-body .linkedtaskflows`).length > 0) {
    import("./linked-taskflows.es6" /* webpackChunkName: "linked-taskflows" */).then((linkedTjs) => {
      linkedTaskflows = linkedTjs;
      linkedTaskflows.init(groupId, selector);
    });
  }
};
const enableTodos = (selector) => {
  if ($(`${selector} .kotive-task-body .todos`).length > 0) {
    import("./todos.es6" /* webpackChunkName: "todos" */).then((todosJs) => {
      todos = todosJs;
      todos.initAsField(groupId, selector);
    });
  }
};
const enableWysiwyg = (selector) => {
  if ($(`${selector} .kotive-task-body trix-editor`).length > 0) {
    import("./wysiwyg.es6" /* webpackChunkName: "wysiwyg" */).then((wysiwygJs) => {});
  }
};
const enableDynamicFields = (selector) => {
  if ($(`${selector} .kotive-task-body .dynamic-field`).length > 0) {
    import("./dynamic-fields.es6" /* webpackChunkName: "dynamic-fields" */).then((dynamicFieldsJs) => {
      dynamicFields = dynamicFieldsJs;
      dynamicFields.initAsField(groupId, selector);
    });
  }
};
const enableVideo = (selector) => {
  if ($(`${selector} .kotive-task-body .video`).length > 0) {
    const width = $(`${selector} .kotive-task-body`).innerWidth() - 30;
    const height = width / 1.777;
    $(`${selector} .kotive-task-body .video`).width(width);
    $(`${selector} .kotive-task-body .video`).height(height);
  }
};
const enableAudio = (selector) => {
  if ($(`${selector} .kotive-task-body .audio`).length > 0) {
    const width = $(`${selector} .kotive-task-body`).innerWidth() - 30;
    const height = width / 1.777;
    $(`${selector} .kotive-task-body .audio`).width(width);
    $(`${selector} .kotive-task-body .audio`).height(height);
  }
};
const enablePowerpoint = (selector) => {
  if ($(`${selector} .kotive-task-body .powerpoint`).length > 0) {
    const width = $(`${selector} .kotive-task-body`).innerWidth() - 30;
    const height = width / 1.777;
    $(`${selector} .kotive-task-body .powerpoint`).width(width);
    $(`${selector} .kotive-task-body .powerpoint`).height(height);
  }
};

const enableTimerFields = (selector) => {
  if ($(`${selector} .kotive-task-body .timer-container`).length > 0) {
    import("./timer-field.es6" /* webpackChunkName: "timer-field" */).then((timerFieldJs) => {
      timerField = timerFieldJs;
      timerField.init(selector);
    });
  }
};
export const observeChangedFieldsInForm = (selector) => {
  $(`${selector}`).on("input blur", ".kotive-field-input", (e) => {
    $(e.target).closest(".kotive-field-input").addClass("kotive-field-input-nudge");
  });
};
const observeEnterPress = (selector) => {
  // $(`${selector} .kotive-task-body form :input`).on('mouseup', (e)=> {
  //   enablePageControls(selector)
  // })

  $(`${selector} .kotive-task-body form`).on("keypress", (e) => {
    if (e.which == 13 && $("textarea").is(":focus") !== true && $("trix-editor").is(":focus") !== true) {
      if (reqType == "modal") {
        $(`${selector} .actions .button.positive`).trigger("click");
      } else {
        $(`${selector} #kotive-task-save-btn`).trigger("click");
      }
      return false;
    }
  });
};
